import path from 'path-browserify';

const staticUrl = 'https://static.happynumbers.com';
const staticAwsUrl = 'https://happy-s3-static2.s3.us-west-1.amazonaws.com';

const TEACHERS_BASE = import.meta.env.VITE_TEACHERS_BASE || '/';

export const buildStaticUrl = (resource: string) =>
  new URL(resource, staticUrl).href;

export const buildAwsStaticUrl = (resource: string) =>
  new URL(resource, staticAwsUrl).href;

export const buildFullUrl = (url: string, segments: string) =>
  path.join(url, segments);

export const buildRedirectUrl = (url: string, redirectTo: string) =>
  path.join(new URL(url).origin, TEACHERS_BASE, redirectTo);

export const buildFullPath = (...paths: string[]) =>
  path.join(TEACHERS_BASE, ...paths);
